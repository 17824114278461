<template>
  <div>

    <b-card class="p-1">
      <b-row>
        <b-col>
          <b-row align-h="end">
            <b-button
              v-if="$can('assign-entity-leader','admin-permissions')"
              variant="primary"
              class="mr-0 mr-sm-1 mb-1 mb-sm-0"
              :to="{name:'assign-entity-leader'}"
            >
              Assign Leader
            </b-button>
            <b-button
              variant="primary"
              :to="{name:'assign-entity-member'}"
            >
              Assign Member
            </b-button>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-table
        ref="table"
        :items="usersProvider"
        :fields="tableColumns"
        show-empty
        empty-text="No users in this entity"
        responsive="lg"
      >
        <template #cell(actions)="{item}">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'edit-user-permission', params: { username: item.username , entityId: entityId } }">
              <feather-icon icon="EditIcon" />
              <span> Edit User Permissions </span>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'edit-user-role', params: { username: item.username , entityId: entityId } }">
              <feather-icon icon="EditIcon" />
              <span> Edit User Roles </span>
            </b-dropdown-item>
            <b-dropdown-item @click="confirmDeleteMember(entityId,item.username)">
              <feather-icon icon="XCircleIcon" />
              <span> Delete </span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import deleteMembers from '@/common/compositions/permissions/deleteMemberFromEntity'

export default {
  name: 'PortalUsers',
  data() {
    return {
      entityId: this.$store.getters['mainEntity/getEntityId'],
      tableColumns: [
        { key: 'id' },
        { key: 'username' },
        { key: 'email' },
        { key: 'actions' },
      ],
    }
  },
  setup() {
    const { table, confirmDeleteMember } = deleteMembers()
    return {
      table,
      confirmDeleteMember,
    }
  },
  watch: {
    entityId() {
      this.$refs.table.refresh()
    },
  },
  methods: {
    usersProvider() {
      const promise = this.$portalUsers.get(`v1/entity/users/${this.entityId}`)
      return promise.then(res => {
        const { leader } = res.data.data
        const { members } = res.data.data
        const allMembers = [leader, ...members]

        return allMembers || []
      }).catch(() => [])
    },
  },
}
</script>
<style lang="scss">

</style>
