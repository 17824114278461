import { ref } from '@vue/composition-api'
import axiosInstances from '@/libs/axios-instances'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default function deleteMembers() {
  const table = ref(null)
  const { successfulOperationAlert, showErrors, confirmDelete } = handleAlerts()
  const deleteMemberFromEntity = (entityId, username) => {
    axiosInstances.portalUsers.delete('v1/entity/member/delete', {
      data: {
        entity: entityId,
        username,
      },
    }).then(() => {
      successfulOperationAlert('Member is deleted from entity successfully')
      table.value.refresh()
    }).catch(err => {
      showErrors(err.response.data.errors)
    })
  }
  const confirmDeleteMember = (entityId, username) => {
    confirmDelete().then(() => {
      deleteMemberFromEntity(entityId, username)
    })
  }

  return { table, confirmDeleteMember }
}
